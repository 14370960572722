import React from "react"
import { css } from "@emotion/react";
import styled from '@emotion/styled';
import BackgroundImage from 'gatsby-background-image';

import Layout from "../components/layout";
import useInicio from '../hooks/useInicio';
import heroCSS from '../css/hero.module.css';
import SEO from "../components/seo";
import UltimosArticulos from '../components/ultimosArticulos';
//import ListadoTarjetas from '../components/listadoTarjetas';
//import UltimasTarjetas from '../components/ultimasTarjetas';
import Carrusel from '../components/carrusel';
import Oceano from '../components/oceano';
import SubscribeForm from '../components/subscribeForm';

const ImagenBackground = styled(BackgroundImage)`
  height: 600px;
`;


const IndexPage = () => {

  const inicio = useInicio();
  const { nombre, contenido, imagen } = inicio[0];

  return (
    <Layout>
      <SEO title="Inicio" />
      <ImagenBackground
        tag="section"
        fluid={imagen.sharp.fluid}
        fadeIn="soft"
      >
        <div 
          className={heroCSS.imagenbg}
        >
          <h1 className={heroCSS.titulo}>Un Sitio para Pensar</h1>
        </div>
        
      </ImagenBackground>
      <div
        css={css`
          max-width: 800px;
          margin: 0 auto;
        `}
        >
        <h1>{nombre}</h1>
        <p
          css={css`text-align: justify`}
        >{contenido}</p>
      </div>
      <br />
      <UltimosArticulos />
      <Oceano />      
      <Carrusel />
      <SubscribeForm />
    </Layout>
  )
}


export default IndexPage
