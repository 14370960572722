import React, { useState} from 'react'
//import { useStaticQuery, graphql } from "gatsby";
import { css } from "@emotion/react";
import {Link} from 'gatsby';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import urlSlug from 'url-slug';

import useTarjetas from '../hooks/useTarjetas';


const Carrusel =()=> {

    const resultado = useTarjetas();
    const invertir = resultado.reverse();
    const [tarjetas] = useState(invertir);

    //console.log(tarjetas[0].codigo);
    
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 8000,
        swipeToSlide: true,
        
        
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    
    return (
        <div 
            css={css`
                margin: 3rem auto 3rem auto;
                max-width: 1200px;
                width: 95%;
            `}>
            <h2 
                css={ css`
                    margin-bottom: 3rem;
                    
                `}
            >Últimos Mensajes Publicados</h2>
            <Slider {...settings}>
                <div css={css`margin-right: 1rem;`}>
                    <Link to={`/${urlSlug(tarjetas[0].codigo)}`}>
                        <img src={tarjetas[0].imagen.sharp.fluid.src} alt="img0" />
                    </Link>                    
                </div>
                <div css={css`margin-right: 1rem;`}>
                    <Link to={`/${urlSlug(tarjetas[1].codigo)}`}>
                        <img src={tarjetas[1].imagen.sharp.fluid.src} alt="img1" />
                    </Link>
                </div>
                <div css={css`margin-right: 1rem;`}>
                    <Link to={`/${urlSlug(tarjetas[2].codigo)}`}>
                        <img src={tarjetas[2].imagen.sharp.fluid.src} alt="img2" />
                    </Link>
                </div>
                <div css={css`margin-right: 1rem;`}>
                    <Link to={`/${urlSlug(tarjetas[3].codigo)}`}>
                        <img src={tarjetas[3].imagen.sharp.fluid.src} alt="img3" />
                    </Link>
                </div>
                <div css={css`margin-right: 1rem;`}>
                    <Link to={`/${urlSlug(tarjetas[4].codigo)}`}>
                        <img src={tarjetas[4].imagen.sharp.fluid.src} alt="img4" />
                    </Link>
                </div>
                <div css={css`margin-right: 1rem;`}>
                    <Link to={`/${urlSlug(tarjetas[5].codigo)}`}>
                        <img src={tarjetas[5].imagen.sharp.fluid.src} alt="img5" />
                    </Link>
                </div>
                <div css={css`margin-right: 1rem;`}>
                    <Link to={`/${urlSlug(tarjetas[6].codigo)}`}>
                        <img src={tarjetas[6].imagen.sharp.fluid.src} alt="img6" />
                    </Link>
                </div>
                <div css={css`margin-right: 1rem;`}>
                    <Link to={`/${urlSlug(tarjetas[7].codigo)}`}>
                        <img src={tarjetas[7].imagen.sharp.fluid.src} alt="img7" />
                    </Link>
                </div>
                <div css={css`margin-right: 1rem;`}>
                    <Link to={`/${urlSlug(tarjetas[8].codigo)}`}>
                        <img src={tarjetas[8].imagen.sharp.fluid.src} alt="img8" />
                    </Link>
                </div>
            </Slider>
        </div>
    ) 
    
}

export default Carrusel;